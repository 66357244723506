import { Checkbox } from "PFComponents/checkbox";
import PropTypes from "prop-types";

import css from "./checkboxes_item.module.scss";

const ToggleCheckboxItem = ({ filter, handleChange, disabled }) => (
  <div className={css.root}>
    <Checkbox
      label={filter.title}
      checked={filter.value?.toString() === "true"}
      onChange={(checked) => handleChange(checked ? checked : null)}
      disabled={disabled}
    />
  </div>
);

ToggleCheckboxItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired,
  disabled: PropTypes.bool
};

export default ToggleCheckboxItem;
