import { uniqBy } from "lodash";
import { Checkbox } from "PFComponents/checkbox";
import PropTypes from "prop-types";

import css from "./checkboxes_item.module.scss";

const CheckboxesItem = ({ filter, handleChange, disabled }) => (
  <div>
    {filter.title && <span style={{ paddingBottom: 5, display: "block" }}>{filter.title}</span>}
    <div className={css.root}>
      {filter.options.map((option) => {
        const checked = !!(filter.value || []).find((value) => value === option.id);
        return (
          <Checkbox
            disabled={disabled}
            key={option.id}
            label={option.text}
            checked={checked}
            onChange={(checked) => {
              const newValues = checked
                ? uniqBy([...(filter.value ? filter.value : []), option.id])
                : filter.value.filter((value) => value !== option.id);
              handleChange(newValues);
            }}
          />
        );
      })}
    </div>
  </div>
);

CheckboxesItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, text: PropTypes.string })).isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, text: PropTypes.string }))
  }).isRequired,
  disabled: PropTypes.bool
};

export default CheckboxesItem;
